@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles/colors.scss";
@import "../public/index.scss";


$medium: 768px;

body {
  margin: 0;
  font-family: "Manrope";
  color: $text-dark;
}

p {
  color: $text-dark-secondary !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1480px;
  margin: 0 auto;
  padding: 0px 32px 0px 32px;

  @media screen and (max-width: $medium) {
    padding: 0px 16px 0px 16px;
  }
 
}

.footer-width {
  max-width: 1480px;
}

html {
  scroll-behavior: smooth;
}

.font-semibold {
  font-weight: 400 !important;
}

.font-semibold-v2 {
  font-weight: 600;
}