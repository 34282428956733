// Primary Colors
$primary-900: #692015;
$primary-800: #892B1C;
$primary-700: #A73927;
$primary-600: #C24B36; 
$primary-500: #DA614A;
$primary-400: #ED7A62;
$primary-300: #FB957F;
$primary-200: #FFB29F;
$primary-100: #FFD1C4;
$primary-50: #FFEFEB;

$secondary-900: #004145;
$secondary-800: #00555B;
$secondary-700: #006A71;
$secondary-600: #007F86;
$secondary-500: #2D939A; 
$secondary-400: #52A8AE;
$secondary-300: #76BCC1;
$secondary-200: #9CD0D3;
$secondary-100: #C3E3E5;
$secondary-50: #EBF6F6;

// Typography
$text-color-primary: $primary-900;
$text-color-secondary: $secondary-900;

$text-dark: #1D0900;
$text-white:  #fff;
$text-dark-secondary: #535A5F;
$company-carousel-background: #F9FAFB;
