@font-face {
    font-family: 'Manrope';
    src: url('./fonts/manrope-thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Manrope';
    src: url('./fonts/manrope-light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Manrope';
    src: url('./fonts/manrope-regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Manrope';
    src: url('./fonts/manrope-medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Manrope';
    src: url('./fonts/manrope-semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Manrope';
    src: url('./fonts/manrope-bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Manrope';
    src: url('./fonts/manrope-extrabold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }