@import "./styles/colors.scss";
$medium: 768px;

.link-button { 
  height: 48px;
  min-width: 120px;
  padding: 10px 18px;
  color: $text-white;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;
  display: inline-block;
  align-content: center;
  align-self: center;
  cursor: pointer;
}

.header {
  font-weight: 600;
}

.wrapped-button {
  padding: 10px 168px;
  color: $text-white;
  height: 44px;
}

.shadow-none {
  box-shadow: none;
}

.font-size-display-xl {
  font-size: 60px;
  @media screen and (max-width: $medium) {
    font-size: 40px;
  }
}

.font-size-display-lg {
  font-size: 48px;
  @media screen and (max-width: $medium) {
    //font-size: 24px;
  }
}

.font-size-display-md {
  font-size: 36px;
  @media screen and (max-width: $medium) {
    font-size: 30px;
   
  }
}

.font-size-display-sm {
  font-size: 30px;
  @media screen and (max-width: $medium) {
   
  }
}

.font-size-text-xl {
  font-size: 20px;
  @media screen and (max-width: $medium) {
    //font-size: 18px;
  }
}

.font-size-text-lg {
  font-size: 18px;
  @media screen and (max-width: $medium) {
    font-size: 16px;
   
  }
}

.font-size-text-md {
  font-size: 16px;
  @media screen and (max-width: $medium) {
    font-size: 14px;
   
  }
}

.font-size-text-sm {
  font-size: 14px;
  @media screen and (max-width: $medium) {
    font-size: 12px;
   
  }
}

.line-height-display-xl {
  line-height: 72px;
  @media screen and (max-width: $medium) {
    line-height: 38px;
  }
}

.line-height-display-md {
  line-height:  44px
}

.line-height-display-sm {
  line-height:  38px
}

.line-height-text-xl {
  line-height: 30px;
}

.line-height-text-lg {
  line-height: 28px;
}

.line-height-text-md {
  line-height: 24px;
}

.line-height-text-sm {
  line-height: 20px;
}
.company-carousel, .gray-section {
  background-color: $company-carousel-background;
 // width: 100vw;
 // margin: 0 auto;
 
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  min-width: 300px;
}

.solution-icon-wrapper {
  border-radius: var(--radius-lg, 10px);
  border: 1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #E4E7EC);
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}

.container-padding {
  padding-left: 32px;
  padding-right: 32px;
}

.breadcrumb-container {
  border-radius: var(--radius-sm, 10px);
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 12px;
  padding-right: 12px;
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}

.breadcrum-text-wrapper {
  padding-top: 2px;
  padding-bottom: 2px;
}

.breadcrumb-text-border{
  border: 1px solid var(--Colors-Border-border-primary, #fff);
}

.breadcrumb-head {
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));;
}

.icon-backround { 
  max-width: 48px;
  padding: 12px;
  border-radius: var(--radius-lg, 10px);
  border: 1px solid var(--Gradient-skeuemorphic-gradient-border, rgba(255, 255, 255, 0.12));
  background-color: #C24B36;
  max-height: 48px;
  box-shadow: 0px 0px 0px 1px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -2px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  display: flex;
  align-content: center;
  justify-content: center;
}

.input-base {
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  background: var(--Colors-Background-bg-primary, #FFF);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
}

.header-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%; /* Zawsze okrągły kształt */
  background-color: var(--primary-500, $primary-500); /* Użyj zmiennej koloru, jeśli jest dostępna */
  border: 4px solid var(--primary-100, $primary-100); /* Użyj zmiennej koloru, jeśli jest dostępna */
  box-sizing: border-box; /* Uwzględnia border w rozmiarze */
}


.embla-container {
  position: relative;
  overflow: hidden;
}

.embla-container::before,
.embla-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 32px;
  z-index: 1;
  pointer-events: none;
}

.embla-container::before {
  left: 0;
  //rgba(249, 250, 251, 0.00) 0%, #F9FAFB 81.41%
  //background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
  background: linear-gradient(to left, rgba(249, 250, 251, 0.00) 0%, #F9FAFB 81%);
}

.embla-container::after {
  right: 0;
 // background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
  background: linear-gradient(to right, rgba(249, 250, 251, 0.00) 0%, #F9FAFB 81%);
}

.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  margin-bottom: 30px;
}

.App-header {
  background-color: #EBF6F6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.linkedin-icon {
  height: 24px;
  width: auto;
}

.validated-input-error {
  border: 1px solid red !important;
}

.linkedin-image {
  border-radius: 8px;
}

.logo {
  width: 103px;
  height: 43px;
}